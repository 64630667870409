<template>
  <form
    class="contact-form"
    :class="openForm ? 'contact-form-open' : ''"
    :action="action"
    @submit="onFormSubmit"
    v-if="enabled"
  >
    <div v-if="title || description">
      <div class="b6">
        <h3 v-if="title" class="contact-title b4">{{ title }}</h3>
        <p v-if="description" class="contact-description">{{ description }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-6">
        <v-input
          class="input-block b4"
          type="text"
          placeholder="Имя*"
          name="fname"
          validate-rules="required"
          label
        ></v-input>
      </div>
      <div class="d-6">
        <v-input
          class="input-block b4"
          type="text"
          placeholder="Фамилия*"
          name="lname"
          validate-rules="required"
          label
        ></v-input>
      </div>
      <div class="d-6">
        <v-input
          class="input-block b4"
          type="text"
          placeholder="Телефон*"
          name="phone"
          validate-rules="required"
          label
        ></v-input>
      </div>
      <div class="d-6">
        <v-input
          class="input-block b4"
          type="text"
          placeholder="Почта*"
          name="email"
          validate-rules="required|email"
          label
        ></v-input>
      </div>
      <div class="d-12">
        <v-input
          class="input-block b4"
          type="text"
          placeholder="Компания*"
          name="company"
          validate-rules="required"
          label
        ></v-input>
      </div>
      <div class="d-12">
        <v-input
          class="input-block"
          type="text"
          placeholder="Комментарий"
          name="comment"
          label
          :lines="3"
        ></v-input>
      </div>
    </div>
    <div class="t4" :class="centered ? 'd-center' : ''">
      <v-checkbox
        name="privacy-policy"
        text='Я даю согласие на обработку персональных данных на условиях <a href="/privacy-policy">Политики обработки персональных данных</a>'
        validate-rules="required"
        @set-value="setCookie"
      ></v-checkbox>
      <button
        class="button button-small t4"
        type="submit"
        :disabled="errors.any()"
      >
        Отправить запрос
      </button>
    </div>

    <input
      v-for="(value, name) in extra"
      :key="name"
      type="hidden"
      :name="name"
      :value="value"
    />
  </form>
</template>

<script>
import VInput from "@/components/v-input.vue";
import VCheckbox from "@/components/v-checkbox.vue";

const events = [
  'scroll',
  'keydown',
  'keypress',
  'wheel',
  'click',
  'mousemove',
  'contextmenu',
  'touchmove',
];

export default {
  name: "v-contact-form",
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  components: {
    VInput,
    VCheckbox
  },
  props: {
    action: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    facebook: {
      type: String,
      required: false,
      default: "",
      validate(value) {
        return typeof value === "string" && value.length > 0;
      },
    },
    metrika: {
      type: Object,
      required: false,
      validate(value) {
        return typeof value.id === "number" && typeof value.target === "string";
      },
      default: undefined,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    openForm: {
      type: Boolean,
      default: false,
    },
    antispam: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      extra: {},
      listeners: [],
      enabled: true,
    };
  },
  created() {
    const extra = {};
    if (
      typeof window.ga !== "undefined" &&
      typeof window.ga.getAll !== "undefined"
    ) {
      extra.ga = window.ga.getAll()[0].get("clientId");
    }
    extra.ym = this.getYM();
    extra.source = document.URL;
    this.extra = { ...extra, ...this.getUTMMetrics() };
    this.enabled = !this.antispam;
    if (this.antispam) {
      this.registerFW();
    }
  },
  beforeDestroy() {
    if (this.antispam) {
      this.unregisterFW();
    }
  },
  methods: {
    track() {
      return this.notifyFacebook() && this.notifyMetrika();
    },
    notifyFacebook() {
      if (this.facebook && typeof window.fbq !== "undefined") {
        window.fbq("track", this.facebook);
      }
      return true;
    },
    notifyMetrika() {
      const defaults = {
        event: "reachGoal",
        params: {},
      };
      if (this.metrika && typeof window.ym !== "undefined") {
        const data = { ...defaults, ...this.metrika };
        window.ym(data.id, data.event, data.target, data.params);
      }
      return true;
    },
    onFormSubmit(e) {
      const validator = this.$validator;
      const cls = "form-invalid";
      validator.validateAll().then((success) => {
        if (success) {
          e.target.classList.remove(cls);
          this.track();
          return true;
        }
        e.target.classList.add(cls);
        e.preventDefault();
        return false;
      });
    },
    getYM() {
      const match = document.cookie.match("(?:^|;)\\s*_ym_uid=([^;]*)");
      return match ? decodeURIComponent(match[1]) : "";
    },
    getUTMMetrics() {
      const params = document.URL.match(/utm_[a-z]+=([^#&]+)/g) || [];
      const result = {};
      params.forEach((value) => {
        const parts = value.split("=");
        result[parts[0]] = parts[1] || "";
      });
      return result;
    },
    registerFW() {
      if (Array.isArray(events) && typeof document.body !== 'undefined') {
        events.forEach((name) => {
          document.body.addEventListener(name, this.runFW);
          this.listeners.push(name);
        });
      }
    },
    unregisterFW() {
      if (
        Array.isArray(this.listeners)
        && typeof document.body !== 'undefined'
      ) {
        this.listeners.forEach((name) => {
          document.body.removeEventListener(name, this.runFW);
        });
      }
    },
    runFW(event) {
      this.enabled = true;
      this.unregisterFW();
    },
    setCookie(data) {
      this.extra['policy-accepted'] = data;
      if (data === true && !this.getCookie('privacy-policy')) {
        this.setPolicyCookie();
      }
    },
    setPolicyCookie() {
      const policy = 15;
      const lifetime = 1000 * 60 * 60 * 24 * 365;
      const expires = new Date(new Date().getTime() + lifetime).toGMTString();
      document.cookie = `privacy-policy=${policy}; expires=${expires}; path=/`;
    },
    getCookie(name) {
      const matches = document.cookie.match(
        new RegExp(
          `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
        ),
      );
      return !!matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_functions.scss";
@import "../styles/_mixins.scss";

.contact-form-open {
  .contact-description {
    @include font-size(4);
  }
  .contact-policy {
    @include font-size(1);
  }
}

@include mobile() {
  .contact-form {
    width: 100%;
  }
}
</style>
