<template>
  <div
    class="input"
    :class="{
      'input-labeled': label,
    }"
  >
    <div class="input-box">
      <textarea
        class="input-field input-textarea"
        v-if="lines > 1"
        :placeholder="placeholder"
        :name="name"
        v-validate="validateRules"
        :rows="lines"
        :placeholder-shown="text ? '' : undefined"
        :disabled="disabled ? '' : undefined"
        :value="text"
        v-on="inputListeners"
      />
      <input
        class="input-field input-placeholder"
        v-else
        :type="type"
        :placeholder="placeholder"
        :name="name"
        v-validate="validateRules"
        :placeholder-shown="text ? '' : undefined"
        :disabled="disabled ? '' : undefined"
        :value="text"
        v-on="inputListeners"
      />
      <label v-if="label" class="input-label" :for="name">
        <div class="input-label-content">
          {{ fixedLabel }}
        </div>
      </label>
      <div class="input-borders"></div>
    </div>
    <div v-if="$slots.default && $slots.default.length" class="input-extra">
      <slot></slot>
    </div>
    <div v-if="errors.first(name)" class="input-message">
      {{ errors.first(name) }}
    </div>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  name: "v-input",
  props: {
    name: String,
    type: {
      type: String,
      default: "text",
    },
    placeholder: String,
    value: [String, Number],
    validateRules: String,
    label: [Boolean, String],
    icon: {
      type: String,
      default: "",
    },
    compact: Boolean,
    lines: {
      type: Number,
      default: 1,
    },
    disabled: Boolean,
    button: String,
  },
  data() {
    return {
      text: this.value === 0 ? "0" : this.value,
    };
  },
  computed: {
    fixedLabel() {
      return this.label === true ? this.placeholder : this.label;
    },
    inputListeners() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input(event) {
          vm.text = event.target.value;
          vm.$emit("input", event.target.value);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_functions.scss";
@import "../styles/_mixins.scss";

$input-default-border: 2px $gray-100 solid;
$input-default-placeholder: $gray-200;
$input-error-border: $red;
$input-focused-border: $blue-purple-default;
$input-disabled-border: $gray-100;
$input-disabled-background: $white;
$input-default-background: transparent;
$input-error-border-color: $red;
$input-focused-border-color: $blue-purple-default;

@mixin input-apos($top: 0, $left: 0) {
  position: absolute;
  top: #{$top};
  left: #{$left};
  width: 100%;
  height: 100%;
}

@mixin input-round-rects() {
  box-sizing: border-box;
  border: $input-default-border;
  @if $border-radius {
    border-radius: $border-radius;
  }
}

@mixin input-field {
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: rem(space(2)) rem(space(3));
  font: inherit;
}

@mixin input-label {
  display: none;
  @include input-apos(rem(space(3) * -1), rem(space(2)));
  @include font-size(1);
  z-index: 5;
  pointer-events: none;
  transition: all $fx, background 0ms;
  touch-action: none;
  color: $input-default-placeholder;
}

@mixin input-label-content {
  width: 90%;
  display: block;
  background: rgba($input-default-background, 0);
  transition: padding $fx, transform $fx;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.input {
  display: inline-block;
  line-height: $line-height;
  color: $dark;
  @include font-size(2);
  user-select: none;

  &-box {
    position: relative;
  }

  &-label {
    @include input-label;
    width: auto;
    height: auto;
    line-height: initial;
    padding: 0 rem(space(1));
    z-index: 5;
    pointer-events: none;
    transition: all $fx, background 0ms;
    touch-action: none;
    color: $input-default-placeholder;
    text-align: left;
    background-color: $white;
  }

  &-label-content {
    max-width: 100%;
    display: inline-block;
    background: rgba($input-default-background, 0);
    transition: padding $fx, transform $fx, background $fx;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &-borders {
    @include input-apos();
    @include input-round-rects();
    transition: border $fx, background $fx;
    background: $input-default-background;
  }

  &-field::-ms-clear {
    display: none;
  }

  &-field::-webkit-search-cancel-button {
    display: none;
  }

  &-field {
    appearance: none;
    position: relative;
    z-index: 3;
    @include input-field;
    outline: 0;
    border: 0;
    background: transparent;
    width: 100%;
    min-height: rem(space(8));
    resize: none;
    color: $dark;

    &:focus {
      outline: 0;
      border: 0;
      background: transparent;
    }

    &:disabled {
      outline: none;
      border: 0;
      background: transparent;
      cursor: not-allowed;

      @if $border-radius {
        border-radius: $border-radius;
      }
    }

    &::placeholder {
      color: $input-default-placeholder;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $input-default-placeholder;
    }

    &::-ms-input-placeholder {
      color: $input-default-placeholder;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-internal-autofill-selected {
      font-family: $font-family;
      font-weight: $font-weight-regular;
      line-height: $line-height;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 100rem $white;
      -webkit-text-fill-color: $dark;
      border: $input-default-border;
      @if $border-radius {
        border-radius: $border-radius;
      }
    }

    &:-webkit-autofill:focus {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &-field[aria-invalid="true"] ~ &-borders,
  &-error &-borders {
    border-color: $input-error-border;
  }

  &-field:focus ~ &-borders {
    border-color: $input-focused-border;
  }

  &-field:focus ~ &-label {
    display: block;
  }

  &-field:disabled ~ &-borders {
    border-color: $input-disabled-border;
    background: $input-disabled-background;
  }

  &-field:disabled ~ &-button,
  &-field[aria-invalid="true"] ~ &-button {
    pointer-events: none;
  }

  &-field[aria-invalid="true"] ~ &-button &-icon {
    pointer-events: all;
    cursor: not-allowed;
  }

  &-field[aria-invalid="true"]:not(:focus) ~ &-label,
  &-error &-field[placeholder-shown]:not(:focus) ~ &-label {
    color: $input-error-border-color !important;
  }

  &-field:focus ~ &-label {
    color: $input-focused-border-color !important;
  }

  &-field:disabled ~ &-borders:before {
    background: $input-disabled-background;
  }

  &-field:disabled ~ &-label {
    @include input-label;
    display: block !important;
  }

  &-field:disabled ~ &-label &-label-content {
    @include input-label-content;
    display: inline-block !important;
    transform: translateY(0%);
    padding: 0;
  }

  &-block {
    display: block;
  }

  &-message {
    font: inherit;
    @include font-size(1);
    padding: 0;
    translate: max-height $fx, padding $fx;
    max-height: 0;
  }

  &-error,
  &-message {
    margin: rem(space(1)) rem(space(2));
    max-height: rem(space(4));
    color: $red;
  }

  &-extra {
    position: relative;
    z-index: 2;
  }
}
</style>
