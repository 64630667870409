<template>
  <div :class="setClassNames">
    <div class="checkbox" :class="text ? 'checkbox-block' : ''">
      <label class="checkbox-box" :for="id">
        <input
          class="checkbox-input"
          type="checkbox"
          :id="id"
          :name="name"
          v-validate="validateRules"
          :disabled="disabled"
          :checked="checked"
          @change="setValue"
        />
        <span class="checkbox-button">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <rect width="25" height="25" rx="8" />
            <rect width="14" height="14" transform="matrix(1 0 0 -1 6 19)" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.5187 6.29774L10.232 14.5704L8.00281 11.7417C7.02008 10.9487 5.47479 11.8728 6.17741 13.0612L8.82681 17.39C9.24698 17.9187 10.232 18.4463 11.2148 17.39C11.6349 16.8613 19.6415 7.48506 19.6415 7.48506C20.6254 6.42881 19.3614 5.50582 18.5187 6.29774Z"
              fill="white"
            />
            <defs>
              <pattern
                id="pattern0_2705_2233"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use xlink:href="#image0_2705_2233" />
              </pattern>
              <image
                id="image0_2705_2233"
                width="1"
                height="1"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC"
              />
            </defs>
          </svg>
        </span>
      </label>
      <div class="checkbox-text" v-html="text"></div>
    </div>
    <div class="input-message" v-if="errors.first(name)">
      {{ errors.first(name) }}
    </div>
  </div>
</template>

<script>
import icon from "@/assets/icons/tick.svg";

export default {
  inject: ["$validator"],
  name: "v-checkbox",
  emits: ["set-value"],
  props: {
    text: String,
    name: String,
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    validateRules: String,
  },
  computed: {
    icon: () => icon,
    setClassNames() {
      const classNames = {
        "input-has-trailing": this.hasIcon,
        "input-compact": this.compact,
        "input-labeled": this.label,
        "input-error": this.errors.has(this.name),
        "input-focused": this.focused,
      };
      if (this.classNames) {
        this.classNames.split().forEach((item) => {
          classNames[item] = true;
        });
      }
      return classNames;
    },
  },
  data() {
    const random = Math.round(Math.random() * (35535 - 10) + 10);
    return {
      id: `checkbox-${random}`,
    };
  },
  methods: {
    setValue(e) {
      this.$emit("set-value", e.target.checked);
    },
  },
};
</script>
